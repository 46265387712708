
import { getSeatingChoiceUIDistribution } from "./features/seatingChoiceUI" 
import { oneClickDistribution } from "./features/oneClickDistribution";
import { confettiDistribution } from "./features/confettiDistribution";
import { getFeatureFlag } from "./utils"
import { variantOptions } from "../types"

import { logger } from "../logger";
import { onExitModalDistribution } from "./features/onExitModalDistribution";

export const configuration = {
  "SEATING_CHOICE_UI": {
    states: ["ENABLED", "DISABLED"],
    distributionLogic: getSeatingChoiceUIDistribution
  },
  "test_seatmap_one_click_desktop": {
    states: ["TRUE", "FALSE"],
    distributionLogic: oneClickDistribution,
  },
  "test_seatmap_one_click_mobile": {
    states: ["TRUE", "FALSE"],
    distributionLogic: oneClickDistribution,
  },
  "test_seatmap_confetti": {
    states: ["TRUE", "FALSE"],
    distributionLogic: confettiDistribution
  },
  "test_seatmap_on_exit_modal": {
    states: ["TRUE", "FALSE"],
    distributionLogic: onExitModalDistribution
  }
}


export const extractVariants = (args: variantOptions): { [key: string]: string } => {
  const allFeatureFlags: { [key: string]: string } = {}

  
  for (const [feature, setup] of Object.entries(configuration)) {
    try{
      const featureState: string = getFeatureFlag(setup["distributionLogic"], setup["states"], args)
      allFeatureFlags[feature] = featureState
      
    }catch(err){
      const error = new Error(`Error encountered when fetching flag for feature: ${feature}. Error: ${String(err)}`);
      logger.error(error)
      logger.sentryError(error)
    }
      
  }

  return allFeatureFlags
}

