import { getBasket } from "./basket";
import { logger } from "./logger";
import { getCurrentTrip } from "./trip";
import { BasketItem } from "./types";
import { getGlobalObject } from "./utils";

const MINIMUM_VALID_TIME = 360000 // Basket must be valid for at least 6 minutes at all times
// NOTE: This time ensures that the basket does not become invalid from time the user purchased to fulfillment call

export class BasketValidator {
    basket: Record<string, BasketItem>;
    removed: Record<string, BasketItem>;
    private timer: any;

    constructor() {
        this.basket = {} as Record<string, BasketItem>;
        this.removed = {} as Record<string, BasketItem>;
        this.timer = Function;
    }

    updateBasket = (basket: Record<string, BasketItem>, removed?: Record<string, BasketItem>) => {
        clearTimeout(this.timer);
        this.basket = basket;
        this.setRemoved(removed);
        if (Object.keys(basket).length > 0) {
            const validTimeLeft = this.getValidityTimeLeft(basket);
            this.timer = setTimeout(() => this.revalidateBasket(basket), validTimeLeft);
        }
    }

    private setRemoved = (newRemoved: Record<string, BasketItem> | undefined) => {
        try {
            if (newRemoved === undefined) { // Clear removed items if user reopens widget
                this.removed = {};
                return;
            }
            for (let item in newRemoved) {
                this.removed[item] = newRemoved[item];
            }
            if (Object.keys(newRemoved).length > 0) { // Fire onInvalidBasket callback if new items removed
                const global = getGlobalObject<Window>();
                const onInvalidBasket = global.__GORDIAN__.eventCallbacks?.onInvalidBasket
                if (onInvalidBasket) {
                    onInvalidBasket({ basket: this.basket, removed: this.removed });
                }
            }
        } catch (e) {
            const error = new Error("Set removed items for basket validation failed");
            logger.error(error)
            logger.sentryError(error)
        }
    }

    private getValidityTimeLeft = (basket: Record<string, BasketItem>): number => {
        let current = new Date();
        let diff = Number.MAX_SAFE_INTEGER;
        for (let item in basket) {
            const validUntil = new Date(basket[item].validity.valid_until);
            const itemDiff = validUntil.getTime() - current.getTime();
            if (itemDiff < diff) {
                diff = itemDiff;
            }
        }
        const validTimeLeft = diff - MINIMUM_VALID_TIME;
        return validTimeLeft > 0 ? validTimeLeft : 0;
    }

    revalidateBasket = async (currentBasket: Record<string, BasketItem>): Promise<any> => {
        logger.log('Start basket validation check')

        if (this.getValidityTimeLeft(currentBasket) > 0) { // Ensure the validity time has passed
            this.updateBasket(currentBasket, {});
            return Promise.resolve()
        }

        const { id: tripId, tripAccessToken } = getCurrentTrip();
        // Enqueue the basket check
        const checkResponse = await fetch(
            `https://api.gordiansoftware.com/v2.2/trip/${tripId}/basket/check`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tripAccessToken}`
                }
            }
        );
        const { status } = await checkResponse.json();
        if (status === "queued") {
            const checkedBasket = await this.pollGetBasket(currentBasket) as Record<string, BasketItem> | undefined; // Poll getBasket until basket has been revalidated
            if (checkedBasket) {
                const { newBasket, removed } = await this.removeInvalidProducts(checkedBasket); // Remove any invalid items
                this.updateBasket(newBasket, removed);
                return Promise.resolve();
            } else {
                Promise.reject(new Error("Polling for updated basket failed"))
            }
        } else {
            return Promise.reject(new Error (`Check basket process did not queue. Check basket status: ${status}`));
        }
    }

    private removeInvalidProducts = async (basket: Record<string, BasketItem>) => {
        let newBasket: Record<string, BasketItem> = {};
        const removed: Record<string, BasketItem> = {};
        const apiBasket = [];

        for (let item in basket) {
            const status = basket[item].validity.status;
            if (status === "price_changed" || status === "unavailable") {
                removed[item] = basket[item];
            } else {
                newBasket[item] = basket[item];
                const apiItem = (({ product_id, passenger_id, quantity }) => ({ product_id, passenger_id, quantity }))(basket[item]);
                apiBasket.push(apiItem);
            }
        }

        if (Object.keys(removed).length > 0) { // Remove all invalid items from the basket
            const { id: tripId, tripAccessToken } = getCurrentTrip();
            const updateBasketRequest = await fetch(
                `https://api.gordiansoftware.com/v2.2/trip/${tripId}/basket`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tripAccessToken}`
                    },
                    body: JSON.stringify(apiBasket)
                }
            )
            const response = await updateBasketRequest.json()
            newBasket = response.basket;
        }
        return { newBasket, removed };
    }

    private pollGetBasket = async (currentBasket: Record<string, BasketItem>) => {
        let attempts = 0;
        
        const hasBasketUpdated = (newBasket: Record<string, BasketItem>, currentBasket: Record<string, BasketItem>) => {
            for (let item in newBasket) {
                const currentItemValidity = currentBasket[item].validity;
                const newItemValidity = newBasket[item].validity;
                if (newItemValidity.status === "checking" || newItemValidity.valid_from === currentItemValidity.valid_from) {
                    return false;
                }
            }
            return true;
        }
        
        const executePoll = async (resolve: any, reject: any) => {
            const { basket } = await getBasket();
            attempts++;
            if (hasBasketUpdated(basket, currentBasket)) {
                return resolve(basket);
            } else if (attempts === 30) {
                // Fail if could not get update after 60 seconds (2 second interval * 30 attempts)
                return reject(new Error("Exceeded max get basket attempts"));
            } else {
                setTimeout(executePoll, 2000, resolve, reject); // Poll every 2 seconds
            }
        };
        
        return new Promise(executePoll);
    };

}