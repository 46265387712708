import { variantOptions } from '../../types'

export const confettiDistribution = (args: variantOptions) => {
    const { integration_name, organization_name } = args;
    if (integration_name === "aunt_betty_prod") {
      return [10, 90]
    }
    if (organization_name === "student_universe") {
      return [50, 50]
    }
    return [0, 100]
  }