import { variantOptions } from '../../types'

export const getSeatingChoiceUIDistribution = (args: variantOptions) => {
  const organization_name = args.organization_name
  const integration_name = args.integration_name
  if (organization_name === "deep-wl") {
    return [100,0]
  } else if (integration_name == "student_universe_sandbox") {
    return [100,0]
  } else {
    return [0,100]
  }
}

export const isSeatingChoiceUiEnabled = (variants: { [key: string]: string }): boolean=> {

  const SEATING_CHOICE_UI_FLAG = 'SEATING_CHOICE_UI';
  const SEATING_CHOICE_UI_FLAG_ENABLED = 'ENABLED';
  if ( SEATING_CHOICE_UI_FLAG in variants && variants[SEATING_CHOICE_UI_FLAG] == SEATING_CHOICE_UI_FLAG_ENABLED ) {
        return true
        
    }
  return false

}
