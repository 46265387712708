import { BasketValidator } from './basketValidator';
import { Callbacks, SearchParams } from './types'

/** Internal */
export interface GordianGlobal {
    Gordian?: {
    },
    __GORDIAN__: {
        logger: any;
        trip: any;
        searchParams: SearchParams;
        eventCallbacks?: Callbacks;
        basketValidator?: BasketValidator;
    },
    gordianInternal?: {
        init: any,
        renderSeatmap: any,
        renderBaggageWidget: any,
        toggleModal: any
        seatMapTrigger: any;
        baggageWidgetTrigger: any;
        upsellTrigger: any;
        showUpsell: any;
        noSeatsOnSearch: any;
        noBagsOnSearch: any;
    }
    console: any
}

const fallbackObject = {};

/**
 * Safely get global scope object
 *
 * @returns Global scope object
 */
export function getGlobalObject<T>(): T & GordianGlobal {
    return (typeof window !== 'undefined' ? window : typeof self !== 'undefined' ? self : fallbackObject) as T & GordianGlobal;
}