import { Trip, getCurrentTrip } from "./trip";
import { logger } from "./logger";
import { getGlobalObject } from './utils'
import { searchBags, searchSeats } from './api';
import { getAssetManifest, loadAssets } from './assets'
import { Upsell } from './types'
import { getBasket, getSearchFromBasket } from './basket';
import { variantOptions } from "./types";

import { extractVariants } from './organizationalConfig/variants'
import { trackEvent, createWidgetOnTrack, initializeFullStory } from "./tracking";
import { isSeatingChoiceUiEnabled } from "./organizationalConfig/features/seatingChoiceUI";

const VISUAL_TRACKING_RATE = 0.01

export function toggleModal(show: Boolean) {
    const global = getGlobalObject<Window>()
    if (global.gordianInternal) {
        global.gordianInternal.toggleModal(show)
    }
}

export async function getUpsell(_trip: Trip, _configuration: Upsell) {
    const global = getGlobalObject<Window>();
    const { id: tripId, tripAccessToken, searchIds } = _trip;
    const existingSeatSearchId = searchIds?.seat
    const existingBagSearchId = searchIds?.bag

    const variantArgs : variantOptions = {
        integration_name: _trip.integration,
        organization_name: _trip.organization,
        carriers: _trip.carriers
    }

    const variants: { [key: string]: string } = extractVariants(variantArgs)

    const variantOverrides = _configuration.variants ?? {}
    
    // This should eventually be refactored into a separate function as we incorporate more seatmap variants.
    // The reason we do this "remapping" of variant names ie because the seatmap uses variants generated by V1
    // which are not necessarily the most readable or friendly. This therefore maps readable/clear names into
    // the seatmap variants that have been implemented.
    if(variantOverrides["convenience_fee_text"]) {
        variants["test_reservation_fee_name"] = variantOverrides["convenience_fee_text"]
    }
    if(variantOverrides["convenience_fee_variant"]) {
        variants["convenience_fee_display_variant"] = variantOverrides["convenience_fee_variant"]
    }

    // Dynamically associate the close callback on modal close button
    function associateCallbackClose(callbackClose: any) {
        const closebutton = document.getElementById('modal-close-button');
        closebutton?.addEventListener('click', () => {
            if (callbackClose) {
                callbackClose();
            }
        })

        const gordianOverlay = document.getElementById('gordian-overlay')
        gordianOverlay?.addEventListener('click', (e: Event) => {
            const overlay: HTMLDivElement = e.target as HTMLDivElement
            if (overlay?.classList?.contains('gordian-overlay')) {
                if (callbackClose) callbackClose()
            }
        })

        const bagsCancelButton = document.getElementById('cancel-button')
        bagsCancelButton?.addEventListener('click', () => {
            if (callbackClose) callbackClose()
        })
    }

    if (!global.gordianInternal) {
        // Right now the 'seats product just triggers the default codepath. Don't need to specify bags, same path.
        const { assets, url } = await getAssetManifest('seats', _configuration.manifestUrl);
        await loadAssets(assets, url)
    }
    if (global.gordianInternal) {
        await global.gordianInternal.init({ onTrack: createWidgetOnTrack(tripId, _trip.integration, _trip.organization), disableDefaultFontLoading: _configuration.disableDefaultFontLoading })
        const { allowProducts = ['seats'] } = _configuration
        const allowVisualTracking = variants["allow_visual_tracking"]
        if (allowVisualTracking && Math.random() < VISUAL_TRACKING_RATE) {
            initializeFullStory(tripId, _trip.integration, _trip.organization, allowProducts)
        }
        const existingTriggerContainer = document.getElementById(`gordian-upsell-container-${tripId}`);
        let triggerContainer = existingTriggerContainer;
        if (!existingTriggerContainer) {
            // If no existing upsell widget for this trip, clear all existing widgets from other trips and create a new widget
            clearChildren(_configuration.container)
            triggerContainer = document.createElement("div");
            triggerContainer.id = `gordian-upsell-container-${tripId}`;
            _configuration.container.append(triggerContainer);
            if (_configuration.display === 'card') {
                if (allowProducts.includes('seats') && allowProducts.includes('bags')) global?.gordianInternal?.upsellTrigger(triggerContainer);
                else if (allowProducts.includes('seats')) global?.gordianInternal?.seatMapTrigger(triggerContainer);
                else if (allowProducts.includes('bags')) global?.gordianInternal?.baggageWidgetTrigger(triggerContainer);
            } else if (_configuration.display === 'modal') {
            } else if (_configuration.display === 'embedded') {
            } else {
                throw 'Unknown display configuration. Possible types are card, embedded, or modal.'
            }
        }
        try {
            // This is an inefficient way of running the searches as we could combine the bag and seat search. However because of the way
            // we pass the v1 seat JSON through v2 to the baggage widget, it'll take a bit more time to get that implemented. It's a high priority
            // but will come later as baggage is the focus.

            // Check if anything is in basket
            const { basket } = await getBasket();
            global.__GORDIAN__.basketValidator?.updateBasket(basket);
            logger.sentryContext("Existing Basket", { basket: JSON.stringify(basket) })

            const searchParams = global.__GORDIAN__.searchParams ?? {}

            const getSeats = allowProducts.includes('seats') ? new Promise(async (resolve) => {
                let seatJson = !!existingSeatSearchId ? null : await getSearchFromBasket(basket, 'seat');
                    try {
                        if (!seatJson) {
                            seatJson = await searchSeats(tripId, tripAccessToken, existingSeatSearchId, searchParams)
                        }

                        const callbackLoad = global.__GORDIAN__.eventCallbacks?.onSeatLoad;
                        if (callbackLoad) {
                            callbackLoad();
                        }
                    } catch (e) {
                        logger.sentryTag("no seats on search", "true")
                        
                        if (!isSeatingChoiceUiEnabled(variants)){
                            global?.gordianInternal?.noSeatsOnSearch()
                        }
                        
                        const callbackFail = global?.__GORDIAN__?.eventCallbacks?.onSeatFail;
                        if (callbackFail) {
                            callbackFail();
                        }

                        seatJson = null;
                    }

                return resolve(seatJson);
            }) : Promise.resolve(null)


            const getBags = allowProducts.includes('bags') ? new Promise(async (resolve) => {
                const rawBagJson = !!existingBagSearchId ? null : await getSearchFromBasket(basket, 'bag');

                const searchParams = global.__GORDIAN__.searchParams ?? {}

                let baggageJson;
                if (!rawBagJson) {
                    try {
                        baggageJson = await searchBags(tripId, tripAccessToken, existingBagSearchId, searchParams);

                        const callbackLoad = global.__GORDIAN__.eventCallbacks?.onBagLoad;
                        if (callbackLoad) {
                            callbackLoad();
                        }
                    } catch (e) {
                        logger.sentryTag("no bags on search", "true")
                        global?.gordianInternal?.noBagsOnSearch()

                        const callbackFail = global?.__GORDIAN__?.eventCallbacks?.onBagFail;
                        if (callbackFail) {
                            callbackFail();
                        }

                        baggageJson = null;
                    }
                } else {
                    baggageJson = rawBagJson
                }

                return resolve(baggageJson);

            }) : Promise.resolve(null)

            const modalcontainer = document.createElement("div");
            modalcontainer.id = 'gordian-upsell-container';
            _configuration.container.append(modalcontainer);

            if (_configuration.display === 'card') {
                global?.gordianInternal?.showUpsell(modalcontainer, {
                    allowProducts, showOnLoad: _configuration.showOnLoad ?? false, 
                    getSeatJson: getSeats, 
                    getBaggageJson: getBags,
                    variants:variants,
                    existingBasket: basket, isOptIn: true, priceAdjustment: _configuration.priceAdjustment,
                    priceTextPrepend: _configuration.priceTextPrepend,
                    baggageDisclaimers: _configuration.baggageDisclaimers,
                    widgetVersions: _configuration.widgetVersions,
                    segmentIndex: _configuration.segmentIndex,
                    onBasketChange: onSeatSelection, 
                    onBaggageBasketChange: onBagsSelection, 
                    onError: (error: any) => { logger.error(error) },
                    theme: _configuration.theme,
                    textOverrides: _configuration.textOverrides,
                });

                // attach modal close handler
                const callbackClose = global?.__GORDIAN__.eventCallbacks?.onSeatModalClosed;
                associateCallbackClose(callbackClose);
            } else if (_configuration.display === 'modal') {
                switch (allowProducts.length) {
                    case 0:
                        throw 'Please request atleast one product.'
                        break;
                    case 1:
                        // correct usage in current version
                        if (allowProducts[0] === "seats") {
                            getSeats.then((results) => {
                                results && global?.gordianInternal?.showUpsell(modalcontainer, {
                                    allowProducts: ["seats"], showOnLoad: _configuration.showOnLoad ?? true, getSeatJson: getSeats, getBaggageJson: getBags,
                                    variants:variants,
                                    existingBasket: basket, isOptIn: true, priceAdjustment: _configuration.priceAdjustment,
                                    priceTextPrepend: _configuration.priceTextPrepend,
                                    baggageDisclaimers: _configuration.baggageDisclaimers,
                                    segmentIndex: _configuration.segmentIndex,
                                    onBasketChange: onSeatSelection, 
                                    onBaggageBasketChange: onBagsSelection,
                                    onError: (error: any) => { logger.error(error) },
                                    theme: _configuration.theme
                                })
                                // execute on load

                                // attach modal close handler
                                const callbackClose = global?.__GORDIAN__.eventCallbacks?.onSeatModalClosed;
                                associateCallbackClose(callbackClose);
                            }
                            ).catch((err) => {
                                console.error(err.message)
                            });
                        }
                        if (allowProducts[0] === "bags") {
                            getBags.then((results) => {
                                results && global?.gordianInternal?.showUpsell(modalcontainer, {
                                    allowProducts: ["bags"], showOnLoad: _configuration.showOnLoad ?? true, getSeatJson: getSeats, getBaggageJson: getBags,
                                    variants:variants,
                                    existingBasket: basket, isOptIn: true, priceAdjustment: _configuration.priceAdjustment,
                                    priceTextPrepend: _configuration.priceTextPrepend,
                                    baggageDisclaimers: _configuration.baggageDisclaimers,
                                    widgetVersions: _configuration.widgetVersions,
                                    segmentIndex: _configuration.segmentIndex,
                                    onBasketChange: onSeatSelection, 
                                    onBaggageBasketChange: onBagsSelection, 
                                    onError: (error: any) => { logger.error(error) },
                                    theme: _configuration.theme
                                })

                                // attach modal close handler
                                const callbackClose = global?.__GORDIAN__.eventCallbacks?.onBagModalClosed;
                                associateCallbackClose(callbackClose);
                            }
                            ).catch((err) => {
                                console.error(err.message)
                            });
                        }
                        break;
                    default:
                        // length >= 2
                        console.log('Modal display cannot handle more than 1 products in the current version. Defaulting to seats.')
                        getSeats.then(() => {
                            global?.gordianInternal?.showUpsell(modalcontainer, {
                                allowProducts: ["seats"], showOnLoad: _configuration.showOnLoad ?? true, getSeatJson: getSeats, getBaggageJson: getBags,
                                variants:variants,
                                existingBasket: basket, isOptIn: true, priceAdjustment: _configuration.priceAdjustment,
                                priceTextPrepend: _configuration.priceTextPrepend,
                                baggageDisclaimers: _configuration.baggageDisclaimers,
                                widgetVersions: _configuration.widgetVersions,
                                segmentIndex: _configuration.segmentIndex,
                                onBasketChange: onSeatSelection, 
                                onBaggageBasketChange: onBagsSelection, 
                                theme: _configuration.theme
                            })

                            // execute on load
                            const callbackLoad = global.__GORDIAN__.eventCallbacks?.onSeatLoad;
                            if (callbackLoad) {
                                callbackLoad();
                            }

                            // attach modal close handler
                            const callbackClose = global?.__GORDIAN__.eventCallbacks?.onSeatModalClosed;
                            associateCallbackClose(callbackClose);
                        }
                        ).catch((err) => {
                            console.error(err.message)
                        });
                }
            } else if (_configuration.display === 'embedded') {
                switch (allowProducts.length) {
                    case 0:
                        throw new Error('Please request at least one product.');
                    default:
                        const productsMap: { [key: string]: { getPromise: Promise<unknown> } } = {
                            "seats":
                            {
                                getPromise: getSeats,
                            },
                            "bags":
                            {
                                getPromise: getBags,
                            }
                        };

                        if (allowProducts.length > 1) {
                            console.log('Embedded display cannot handle more than 1 products in the current version. Defaulting to first product in list.');
                        }
                        const productType = allowProducts[0];
                        if (!productsMap.hasOwnProperty(productType)) throw new Error(`unknown product ${productType}`);

                        productsMap[productType].getPromise.then((results) => {
                            results && global?.gordianInternal?.showUpsell(modalcontainer, {
                                allowProducts: [productType],
                                showOnLoad: false,
                                modal: false,
                                getSeatJson: getSeats,
                                getBaggageJson: getBags,
                                variants:variants,
                                existingBasket: basket,
                                isOptIn: true,
                                priceAdjustment: _configuration.priceAdjustment,
                                priceTextPrepend: _configuration.priceTextPrepend,
                                baggageDisclaimers: _configuration.baggageDisclaimers,
                                widgetVersions: _configuration.widgetVersions,
                                segmentIndex: _configuration.segmentIndex,
                                onBasketChange: onSeatSelection,
                                onBaggageBasketChange: onBagsSelection,
                                onError: (error: any) => {
                                    logger.error(error)
                                },
                                theme: _configuration.theme,
                                gordianUseLocalStorage: _configuration.useLocalStorage,
                            });
                        }).catch((err) => console.error(err.message));
                }
            }

        } catch (error) {
            global?.gordianInternal?.noSeatsOnSearch()
            global?.gordianInternal?.noBagsOnSearch()

            const callbackSeatFail = global?.__GORDIAN__?.eventCallbacks?.onSeatFail
            if (callbackSeatFail) {
                callbackSeatFail();
            }

            const callbackBagFail = global?.__GORDIAN__?.eventCallbacks?.onBagFail
            if (callbackBagFail) {
                callbackBagFail()
            }

            logger.error(`${error}`);
            logger.sentryError(error as Error)
        }
    }
}

export async function onSeatSelection(_seatJSON: any, SeatmapBasket: any = { seats: [] }): Promise<any> {
    logger.log('Calling onSeatSelection')
    const global = getGlobalObject<Window>();
    const { id: tripId, tripAccessToken, onBasketChange = (...args: any[]) => { return args } } = getCurrentTrip();
    const mixpanelProperties = { trip_id: tripId, productType: "seats" }
    trackEvent('SDK Completed Selection', mixpanelProperties)
    const products = SeatmapBasket.seats.map((seat: any) => {
        const quantity: number = 1;
        const { product_id, api_passenger_id } = seat;
        return {
            product_id,
            passenger_id: api_passenger_id,
            quantity
        }
    })
    const payload = products;
    const mixpanelPropertiesWithCount = {...mixpanelProperties, productCount: products.length}
    
    products.length ? trackEvent('SDK Add to Basket', mixpanelPropertiesWithCount) : trackEvent('SDK Clear Basket', mixpanelPropertiesWithCount)

    const basketRequest = await fetch(
        `https://api.gordiansoftware.com/v2.2/trip/${tripId}/basket/seat`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tripAccessToken}`
            },
            body: JSON.stringify(payload)
        }
    )
    const basket = await basketRequest.json()
    global.__GORDIAN__.basketValidator?.updateBasket(basket.basket)
    trackEvent('SDK Basket Change Success', mixpanelProperties)
    return Promise.resolve(onBasketChange(basket))
}

export async function onBagsSelection(BaggageBasket: any = []): Promise<any> {
    logger.log('Calling onBaggageSelection')
    const global = getGlobalObject<Window>();
    const { id: tripId, tripAccessToken, onBasketChange = (...args: any[]) => { return args } } = getCurrentTrip();
    const basketRequest = await fetch(
        `https://api.gordiansoftware.com/v2.2/trip/${tripId}/basket/bag`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tripAccessToken}`
            },
            body: JSON.stringify(BaggageBasket)
        }
    )
    const basket = await basketRequest.json()
    global.__GORDIAN__.basketValidator?.updateBasket(basket.basket)
    return Promise.resolve(onBasketChange(basket))
}

function clearChildren(node: HTMLElement) {
    while (node.firstChild) {
        node.removeChild(node.firstChild)
    }
}
