import { getGlobalObject } from './utils';
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

const client = new Sentry.BrowserClient({
    dsn: "https://176c9ba8204a40ca85bbafbad71d4f83@o82379.ingest.sentry.io/5708345",
    integrations: [...Sentry.defaultIntegrations, new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    enabled: process?.env?.NODE_ENV === 'production'
})

const hub = new Sentry.Hub(client);


const PREFIX = 'Gordian Logger';

const global = getGlobalObject<Window>();

class Logger {
    private _enabled: boolean;

    public constructor() {
        this._enabled = false;
    }

    public disable(): void {
        this._enabled = false;
    }

    public enable(): void {
        this._enabled = true;
    }

    public log(...args: any[]): void {
        if (!this._enabled) {
            return;
        }
        // tslint:disable-next-line: no-console
        global.console.log(`${PREFIX}[Log]: ${args.join(' ')}`);
    }

    public error(...args: any[]): void {
        if (!this._enabled) {
            return;
        }
        // tslint:disable-next-line: no-console

        global.console.error(`${PREFIX}[Error]: ${args.join(' ')}`);
    }

    public sentryContext(name: string, object: Object): void {
        hub.setContext(name, object)
    }

    public sentryTag(name: string, value: string): void {
        hub.setTag(name, value)
    }

    public sentryError(error: Error): void {
        hub.captureException(error)
    }
}

global.__GORDIAN__ = global.__GORDIAN__ || {};
const logger = (global.__GORDIAN__.logger as Logger) || (global.__GORDIAN__.logger = new Logger());

export { logger };
