import mixpanel from 'mixpanel-browser';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { logger } from './logger'
import { MixpanelProperties } from './types';

if (window.process.env["NODE_ENV"] === "production") {
    const token = window.process.env["MIXPANEL_TOKEN"] ?? "";
    mixpanel.init(token);
}


const trackEvent = (name: string, properties: MixpanelProperties) => {
    if (window.process.env["NODE_ENV"] === "production") {
        mixpanel.track(name, { distinct_id: properties.trip_id, ...properties })
    } else {
        logger.log(`Logging Mixpanel event: ${name} with properties ${properties}`)
    }

}

const createWidgetOnTrack = (trip_id: string, integration_name: string, organization_name: string) => {
    const onTrack = (name: string, properties: any) => {
        if (window.process.env["NODE_ENV"] === "production") {
            const mixpanelProperties = { ...properties, integration_name, organization_name, distinct_id: trip_id }
            mixpanel.track(name, mixpanelProperties)
        } else {
            logger.log(`Logging Widget Mixpanel event: ${name} with properties ${properties}`)
        }
    }
    return onTrack

}

const initializeFullStory = (trip_id: string, integration_name: string, organization_name: string, products: string[]) => {
    if (window.process.env["NODE_ENV"] === "production") {
        initFullStory({ orgId: 'J83Q2' })
        FullStory('setProperties', {
            type: 'user',
            properties: {
                trip_id,
                integration_name,
                organization_name,
                products,
            },
        });
    }
}

export { trackEvent, createWidgetOnTrack, initializeFullStory }
