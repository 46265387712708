
import { variantOptions } from '../types'

export const getFeatureFlag = (distributionFunc: any, states: Array<string>, args: variantOptions): string => {

  const probDistribution = distributionFunc(args);

  const weightedArray = probDistribution
  for(let i =1;i < weightedArray.length; i++){
    weightedArray[i] = weightedArray[i] + weightedArray[i-1]
  }

  const randomNumber = Math.floor(Math.random() * weightedArray[weightedArray.length-1]);
  let selection = -1
  for(let i = 0; i < weightedArray.length; i++){
    if(randomNumber < weightedArray[i]){
      selection = i
      break;
    }
  }
  if(selection == -1){
    throw Error
  }
  return states[selection]

}
